import SocketioService from '@/services/socketioService';
import axios from 'axios'
import { getWindowUrl } from '@/helpers.js'
import store from '@/store';
import { Role } from '@/helpers';

// const getDefaultState = () => {
//   return {
// 		status: null,
// 		token: localStorage.getItem('token') || '',
// 		user: localStorage.getItem('user') || null,
// 	}
// }

const state = {
	status: null,
	token: localStorage.getItem('token') || '',
	user: null,
	mama: null,
	papa: null
}

// const state = getDefaultState();

const getters = {
	isLoggedIn: () => {
		console.log(`[APP]`, state.token);
		console.log(`[APP]`, !!state.token);
		return !!state.token;
	},
	loginStatus: (state) => {
		if (state.status)	return state.status;
		else return null;
	},
	getUser: state => {
		if (localStorage.getItem('user')) return JSON.parse(localStorage.getItem('user'));
		else return state.user;
	},
	isMaster: () => {
		if (getters.getUser()) {
			if (getters.getUser().role === Role.Admin || getters.getUser().role === Role.Master) {
					return true;
			}
		}
		return false;
	},
	getToken: state => {
		return state.token;
	}
}

const mutations = {
  setRequest(state) {
		state.status = 'loading';
	},
	setSuccess(state, {token, user}) {
		state.status = 'success';
		state.mama = token;
		state.papa = user;
		state.token   = token;
		// state.user    = user;
	},
	setError(state) {
		state.status = 'error';
	},
	setNewToken(state) {
		state.mama = '';
	},
	setLogout(state) {
		state.status = '';
		state.token = '';
		state.user = null;
	},
	setToken: (state, token) => {
		state.token = token;
	},
	setReset: (state) => {
		// Object.assign(state, getDefaultState());
		state.status = null;
	},
};

const actions = {
	async setAuthorizationHeaderFromStorage({ commit }) {
    const storeToken = localStorage.getItem('token');
		const storeUser = JSON.parse(localStorage.getItem('user'));
		console.log(storeToken);
		console.log(storeUser);
    if (storeToken && storeUser) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${storeToken}`
      commit('setSuccess', {storeToken, storeUser});
			// store.dispatch("startSocketIo", null);
			store.dispatch("startSocketIo", null).then(() => { 
				console.log(`[INIT] all done (auth setHeaderStorage)`);
				store.dispatch("setGlobalLoader", { loading: false, init: true, title: '' });
				console.log(`[INIT] initial connection phase done!`);
			});
    }
  },
	loadNewToken: ({ commit }, tokenData) => {
		commit('setNewToken');
		localStorage.setItem('token', tokenData.token);
		localStorage.setItem('user', JSON.stringify(tokenData.user));
		console.log('NEW TOKEN UPDATE:');
		console.log(tokenData.token);
		console.log(tokenData.user);
		const storeToken = localStorage.getItem('token');
		const storeUser = JSON.parse(localStorage.getItem('user'));
		console.log(`[AUTH-LOGIN] <TOKEN> ${storeToken} | <USER> ${storeUser}`);
		if (storeToken && storeUser) {
			SocketioService.renewSocketToken(storeToken);
			axios.defaults.headers.common['Authorization'] = `Bearer ${storeToken}`;
			store.dispatch("setGlobalLoader", { loading: false, init: true, title: '' });
			console.log(`[TOKEN] renewal complete!`);
			console.log(`[TOKEN] ${storeToken}`);
			commit('setSuccess', {storeToken, storeUser});
		}
		// console.log(localStorage.getItem('token'));
		// console.log(localStorage.getItem('user'));
	},
	login: ({ commit }, user) => {
		return new Promise((resolve, reject) => {
			const storeToken = localStorage.getItem('token');
			const storeUser = JSON.parse(localStorage.getItem('user'));
			console.log(`[AUTH-LOGIN] <TOKEN> ${storeToken} | <USER> ${storeUser}`);
			if (storeToken && storeUser) {
				axios.defaults.headers.common['Authorization'] = `Bearer ${storeToken}`;
				commit('setSuccess', {storeToken, storeUser});
				resolve(null);
			} else {
				// let url = helpers.getWindowUrl().protocol + "//" + helpers.getWindowUrl().host + ":" + 3000 + helpers.getWindowUrl().path;
				// const url = "https:" + "//" + getWindowUrl().host + ":" + 3001 + getWindowUrl().path;
				let url = null;
				if (process.env.VUE_APP_LOCALDEV === "true") {
					url = "/login";
				} else {
					url = "https:" + "//" + getWindowUrl().host + "/enatsrv/login";
				}
				console.log(`[AUTH] login url: ${url}`);
				// let url = "http://192.168.88.247:" + 3000 + getWindowUrl().path;
				commit('setRequest')
				// axios.post(url + 'login', {
				axios.post(url, {
					username: user.username,
					password: user.password
				})
				.then(response => {
						const token = response.data.newToken;
						const user  = response.data.userObj;
						console.log(token);
						console.log(user);
						localStorage.setItem('token', token);
						localStorage.setItem('user', JSON.stringify(user));
						axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
						
						console.log(`[AUTH] <TOKEN> ${token}`);
						console.log(`[AUTH] <TOKEN_LOCALSTORAGE> ${localStorage.getItem('token')}`);
						// store.dispatch("startSocketIo", null);
						store.dispatch("startSocketIo", null).then(() => { 
							console.log(`[INIT] all done (auth login)`);
							store.dispatch("setGlobalLoader", { loading: false, init: true, title: '' });
							console.log(`[INIT] initial connection phase done!`);
						});
						commit('setSuccess', {token, user});
						console.log(response);
						resolve(response);
				}).catch(err => { 
						console.log(`[AUTH] ERROR!!!`);
						commit('setError');
						localStorage.removeItem('token');
						localStorage.removeItem('user');
						reject(err);
				});
				// .then(response => {
				// 		const token = response.data.newToken;
				// 		const user  = response.data.userObj;
				// 		console.log(token);
				// 		console.log(user);
				// 		localStorage.setItem('token', token);
				// 		localStorage.setItem('user', JSON.stringify(user));
				// 		axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
				// 		commit('setSuccess', {token, user});
				// 		console.log(response);
				// 		resolve(response);
				// 		console.log(`[AUTH] <TOKEN> ${token}`);
				// 		store.dispatch("startSocketIo", null);
				// }).catch(err => { 
				// 		commit('setError');
				// 		localStorage.removeItem('token');
				// 		localStorage.removeItem('user');
				// 		reject(err);
				// });
			}

		});
		
		// // let url = helpers.getWindowUrl().protocol + "//" + helpers.getWindowUrl().host + ":" + 3000 + helpers.getWindowUrl().path;
		// const url = "https:" + "//" + getWindowUrl().host + ":" + 3001 + getWindowUrl().path;
		// console.log(url);
		// // let url = "http://192.168.88.247:" + 3000 + getWindowUrl().path;
		// return new Promise((resolve, reject) => {
		// 	commit('setRequest')
		// 	axios.post(url + 'login', {
		// 		username: user.username,
		// 		password: user.password
		// 	})
		// 	.then(response => {
		// 			const token = response.data.token;
		// 			const user  = response.data.user;
		// 			localStorage.setItem('token', token);
		// 			localStorage.setItem('user', JSON.stringify(user));
		// 			axios.defaults.headers.common['Authorization'] = "Bearer" +token;
		// 			commit('setSuccess', {token, user});
		// 			console.log(response);
		// 			resolve(response);
		// 	}).catch(err => { 
		// 			commit('setError');
		// 			localStorage.removeItem('token');
		// 			reject(err);
		// 	});
		// });
	},
	logout: ({ commit }) => {
		return new Promise((resolve) => {
			commit('setLogout');
			localStorage.removeItem('token');
			localStorage.removeItem('user');
			delete axios.defaults.headers.common['Authorization'];
			SocketioService.disconnect();
			commit('setReset');
			console.log("LOGOUT");
			resolve();
		});
	},
};

export default {
  namespaced: true,
  state,
	getters,
  mutations,
  actions,
};